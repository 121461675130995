import React from "react";
import dynamic from "next/dynamic";
import { DESKTOP_WIDTH } from "@/constants";
import { useMediaQuery } from "react-responsive";

const MediaQuery = dynamic(() => import("react-responsive"), {
  ssr: false,
});

const useDesktopMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${DESKTOP_WIDTH}px)` });

const DesktopContainer = ({ children }) => {
  return <MediaQuery minWidth={DESKTOP_WIDTH}>{children}</MediaQuery>;
};

export default DesktopContainer;
